<!-- 返利规则 -->
<template>
  <div id="HallRule">
    <van-nav-bar :title="$t('m_mime.flgz')" left-arrow @click-left="onClickLeft">
    </van-nav-bar>
    <div class="hallrule-cont">
      <div class="box">
        <div class="hallList">
          <h3>{{$t('m_rebateRules.fltj')}}:</h3>
          <ul>
            <li>● {{$t('m_rebateRules.zh')}}</li>
          </ul>
        </div>
        <ul class="hallTable">
          <li><strong>{{$t('m_rebateRules.tz')}}</strong><strong>{{$t('m_rebateRules.flje')}}</strong></li>
          <li>
            <span>1&nbsp;≤&nbsp;{{$t('m_rebateRules.ksze')}}&nbsp; &lt;&nbsp;2</span>
            <!-- <span v-if="$i18n.locale == 'vietnamese'">2&nbsp;{{$t('common.CNY')}}</span> -->
            <span>3&nbsp;{{$t('common.CNY')}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/mobile.less";
.van-nav-bar {
  /* background-image: linear-gradient(0deg, #ceab77 10%, #eddbc1 100%) !important; */
  background-color: rgb(50, 59, 92) !important;
}

/deep/.van-nav-bar__title {
  /* color: #38373b !important; */
  color: #ffffff !important;
}

/deep/.van-nav-bar .van-icon {
  /* color: #38373b !important; */
  color: #ffffff !important;
}

#HallRule {
  width: 100%;
  height: 100%;
  background-color: #303e70;

  .box {
    width: 100%;
    padding: 0 0.3rem;
    box-sizing: border-box;

    .hallList {
      width: 100%;
      margin-top: 0.3rem;
      box-sizing: border-box;

      h3 {
        font-size: 0.3rem;
        line-height: 0.5rem;
        color: #9fb0ff;
        font-weight: 500;
      }

      ul li {
        font-size: 0.26rem;
        line-height: 0.5rem;
        color: #fff;
      }
    }

    .hallTable {
      margin-top: 0.2rem;
      background-color: #404040;
      margin-bottom: 0.3rem;
      border-radius: 0.15rem 0.15rem 0px 0px;
      overflow: hidden;
      color: #eecb77;

      li {
        height: 0.6rem;
        border-bottom: 1px solid #ababab;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: flex;

        span {
          font-size: 0.24rem;
        }
      }

      li:first-child {
        background-color: #323131;
      }

      li:last-child {
        border-bottom: none;
      }
    }
  }
}

#HallRule .hallTable li strong:first-child,
#HallRule .hallTable li span:first-child {
  flex: 1;
}

#HallRule .hallTable li:first-child strong {
  color: #9fb0ff;
}

#HallRule .hallTable li strong,
#HallRule .hallTable li span {
  font-size: 0.26rem;
  color: #fff;
  line-height: 0.6rem;
  text-align: center;
  font-weight: 500;
  min-width: 30%;
}
</style>